import React from 'react';
import { Global } from '@emotion/react'

const Font = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'MonumentGrotesk';
        font-weight: bold;
        src: url('/fonts/MonumentGrotesk-Bold.otf') format('opentype');
      }
      @font-face {
        font-family: 'MonumentGrotesk';
        src: url('/fonts/MonumentGrotesk-Regular.otf') format('opentype');
      }
      `}
    /*styles={`
      /*@font-face {
        font-family: "MonumentGrotesk";
        src: local("MonumentGrotesk");
        /*url("/fonts/MonumentGrotesk-Bold.otf") format("opentype");* /
        url("/fonts/GreatVibes-Regular.otf") format("opentype");
        font-weight: bold;
      }* /
      @font-face {
        font-family: "GreatVibes";
        src: local("GreatVibes"),
        url("/fonts/GreatVibes-Regular.otf") format("opentype");
        font-style: regular;
      }
      /* latin * /
      @font-face {
        font-family: 'Heading Font Name';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('./fonts/headingfont.woff2') format('woff2'), url('./fonts/headingfont.woff') format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin * /
      @font-face {
        font-family: 'Body Font Name';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('./fonts/bodyfont.woff2') format('woff2'), url('./fonts/bodyfont.woff') format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      `}*/
  />
);

export default Font