import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Image,
  Flex,
  Spacer,
  Heading,
  useMediaQuery,
  GridItem,
} from '@chakra-ui/react';
import { BasePage, NavBar, ArchiveButton, TopAlignedText, MyExternalLink } from './elements';

function Info(props) {
  const [normal] = useMediaQuery(['(min-width: 30em)']);

  var infoImg = (<Image width='100%' src='/images/Jernej_Simec.jpg' alt='jernej'/>);
  var infoText = (
    <VStack spacing={{base: '24px', sm: '32px'}} alignItems='left' width='100%'>
      <Text lineHeight={{base: '24px', sm: '32px'}} textStyle='normal'>
        Jernej Šimec (1997) is a photographer and visual artist from Slovenia. He holds a degree from <MyExternalLink hoverColor='#6cffd7' href='https://www.aluo.uni-lj.si/'>ALUO</MyExternalLink> in Ljubljana [SI] with a year on <MyExternalLink hoverColor='#6cffd7' href='https://asp.waw.pl/'>Akademia Sztuk Pięknych</MyExternalLink> in Warsaw [PL], where he also attended a 5-month workshop, Art, Made by Walking.
      </Text>
      <Text lineHeight={{base: '24px', sm: '32px'}} textStyle='normal'>
        He is currently on exchange at the <MyExternalLink hoverColor='#6cffd7' href='https://www.belasartes.ulisboa.pt/'>Faculdade de belas-artes</MyExternalLink> in Lisbon [PT], where he studies sculpture. His artistic approach explores walking as an aesthetic practice, which he records through various media. By building installations and intervening in public spaces, he is developing his methods of visual spatial mapping into three-dimensional forms.
      </Text>
      <Text lineHeight={{base: '24px', sm: '32px'}} textStyle='normal'>
      He is available for commissioned work all around the world. Get in touch via <MyExternalLink hoverColor='#6cffd7' href='mailto:jernej.simec@gmail.com'>e-mail</MyExternalLink> or <MyExternalLink hoverColor='#6cffd7' href='https://www.instagram.com/jernejsimec/'>Instagram</MyExternalLink>.
      </Text>
    </VStack>
  );

  if(!normal) {
    return (
      <BasePage title='Info' webTitle={props.webTitle}>
        <VStack width='100%' p='16px' spacing='32px' textAlign='left' color='black'>
          {infoText}
          {infoImg}
          <Box height='16px'/>
        </VStack>
      </BasePage>
    );
  }

  return (
    <BasePage title='Info' webTitle={props.webTitle}>
      <Grid width='100%' p='16px' templateColumns='repeat(3, 1fr)' gap='16px' textAlign='justify' color='black'>
        <GridItem colSpan='2'>
          {infoImg}
        </GridItem>
        <GridItem>
          {infoText}
        </GridItem>
      </Grid>
    </BasePage>
  );
}

export default Info;
